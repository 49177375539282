import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Holder = styled.header`
    mix-blend-mode: ${(props) => (props.inverse ? 'unset' : 'difference')};
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: 10;
    color: ${(props) =>
        props.inverse ? props.theme.colours.black : props.theme.colours.white};
    @media (${(props) => props.theme.breakpoints.md}) {
        top: auto;
        bottom: 0;
    }
    ul {
        list-style: none;
        padding-left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const Item = styled.li`
    display: inline-block;
    margin: 0 0.5rem;
    a {
        text-decoration: none;
    }
    a[aria-current='page'] {
        text-decoration: ${(props) => (props.active ? 'underline' : 'none')};
    }
`;

function Navigation({ neat, toggleNeat, inverse }) {
    return (
        <Holder inverse={inverse}>
            <nav className="Navigation">
                <ul>
                    <Item active={true}>
                        <Link to="/about">About</Link>
                    </Item>
                    <Item active={!neat}>
                        <Link to="/" onClick={() => toggleNeat(false)}>
                            Explore
                        </Link>
                    </Item>
                    <Item active={neat}>
                        <Link to="/" onClick={() => toggleNeat(true)}>
                            List
                        </Link>
                    </Item>
                </ul>
            </nav>
        </Holder>
    );
}

Navigation.propTypes = {
    neat: PropTypes.bool.isRequired,
    toggleNeat: PropTypes.func.isRequired,
};

const mapStateToProps = ({ neat }) => {
    return { neat };
};

const mapDispatchToProps = (dispatch) => {
    return { toggleNeat: (neat) => dispatch({ type: `SET_NEAT`, neat: neat }) };
};

const ConnectedNavigation = connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigation);
export default ConnectedNavigation;
