import { createStore as reduxCreateStore } from "redux";

const reducer = (state, action) => {
  if (action.type === `SET_NEAT`) {
    return {
      neat: action.neat,
    }
  } else if (action.type === `SET_LIGHTBOX`) {
    return {
      lightbox: action.lightbox,
    }
  }
  return state
};

const initialState = {
  neat: false,
  lightbox: false
};

let createStore = () => reduxCreateStore(
  reducer,
  initialState
);

if (typeof window !== `undefined`) {
  createStore = () => reduxCreateStore(
    reducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
  );
}

export default createStore;
