import React from 'react';
import PropTypes from 'prop-types';

import ConnectedNavigation from '../components/molecules/Navigation';
import Transition from '../components/atoms/Transition';

import GlobalStyles from '../components/atoms/GlobalStyles';
import { ThemeProvider } from 'styled-components';
import { theme } from '../utils/styling';
import '../utils/fontface.css';

function Index( props ) {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles/>
        {props.location.pathname === '/' && <ConnectedNavigation />}
        <Transition location={props.location}>
          <main>{props.children}</main>
        </Transition>
      </>
    </ThemeProvider>
  )
}


Index.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Index;
