/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import smoothscroll from 'smoothscroll-polyfill';
import wrapWithProvider from "./wrap-with-provider"

export const wrapRootElement = wrapWithProvider;

smoothscroll.polyfill();

export const shouldUpdateScroll = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  return false;
};